<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <base-material-card
          color="primary"
          icon="mdi-table-merge-cells"
          inline
          class="px-5 py-4 ml-0"
        >
          <template v-slot:after-heading>
            <div class="display-2 font-weight-light">SIG-R Compara</div>
          </template>

          <v-btn
            v-if="accessReleased('SIGR_COMPARA_ADICIONAR')"
            id="btn-novo-comparativo"
            color="success"
            dark
            elevation="1"
            class="mt-5"
            style="float: left"
            relative
            text
            medium
            @click="$router.push('edit')"
          >
            <v-icon
              left
              size="30px"
            >
              mdi-plus-circle
            </v-icon>
            Novo Comparativo
          </v-btn>

          <v-text-field
            id="comparativo-historico-search"
            v-model="search"
            append-icon="mdi-magnify"
            class="ml-auto mt-4 break-search bs-sig-r"
            label="Procurar"
            hide-details
            single-line
            style="max-width: 250px"
          />

          <general-progress-bars
            class="mt-11"
            :items="downloadProgressData"
            prefixText="Baixando Comparativo"
          />

          <v-divider class="mt-10" />

          <v-data-table
            class="sig-r-table"
            :headers="[
              {
                text: '#',
                value: 'id'
              },
              {
                text: 'Título',
                value: 'titulo'
              },
              {
                text: 'Criado por',
                value: 'usuario'
              },
              {
                text: 'Executado em',
                value: 'data_processamento'
              },
              {
                text: 'Status',
                value: 'status'
              },
              {
                sortable: false,
                text: 'Ações',
                value: 'actions',
                class: 'pl-4'
              }
            ]"
            :items="comparativos"
            :search.sync="search"
            :headerProps="{
              sortByText: 'Ordenar Por'
            }"
            :footer-props="{
              itemsPerPageText: 'Itens por Página'
            }"
            :mobile-breakpoint="800"
            :loading="loading"
            :hide-default-header="loading"
            :hide-default-footer="loading"
            no-data-text="Nenhum comparativo disponível"
            no-results-text="Nenhum registro correspondente encontrado"
          >
            <template v-slot:[`item.data_processamento`]="{ item }">
              {{ item.data_processamento | parseToDateBR }}
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <general-status :status="item.status" />
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                id="action-download-comparativo"
                :class="item.status != 'SUCESSO' ? 'cursor-block' : ''"
                :disabled="item.status != 'SUCESSO'"
                color="gray"
                min-width="0"
                class="px-1"
                fab
                icon
                x-small
                @click="baixarComparativo(item)"
                :loading="
                  downloadProgressData[item.id] !== undefined ? true : false
                "
              >
                <v-icon small> mdi-download </v-icon>
              </v-btn>
              <v-btn
                v-if="accessReleased('SIGR_COMPARA_EDITAR')"
                id="action-edit-comparativo"
                :class="!canEditItem(item) ? 'cursor-block' : ''"
                :disabled="!canEditItem(item)"
                color="gray"
                min-width="0"
                class="px-1 ml-n1"
                fab
                icon
                x-small
                @click="$router.push(`edit/${item.id}`)"
              >
                <v-icon small> mdi-pencil </v-icon>
              </v-btn>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    id="action-more-comparativo"
                    color="white"
                    class="ml-1"
                    height="22px"
                    width="22px"
                    fab
                    x-small
                    elevation="1"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small> mdi mdi-dots-vertical </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-if="canRunItem(item)"
                    @click="executarComparativo(item.id)"
                  >
                    <v-list-item-title>
                      <v-icon small> mdi-play </v-icon>
                      Executar
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="item.status == 'SUCESSO'"
                    @click="duplicarComparativo(item.id)"
                  >
                    <v-list-item-title>
                      <v-icon small> mdi-content-copy </v-icon>
                      Duplicar
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="canDeleteItem(item)"
                    @click="openDialogDelete(item.id, item.titulo)"
                  >
                    <v-list-item-title>
                      <v-icon small> mdi-trash-can-outline </v-icon>
                      Deletar
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="openDialogDetalhes(item)">
                    <v-list-item-title>
                      <v-icon small> mdi-file-find-outline </v-icon>
                      Detalhes
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <template v-slot:[`footer.page-text`]>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="getComparativos"
                    >mdi-refresh</v-icon
                  >
                </template>
                <span>Clique aqui para recarregar os comparativos</span>
              </v-tooltip>
            </template>
          </v-data-table>

          <dialog-delete
            :dialog="dialogDelete"
            @update:dialog="dialogDelete = $event"
            @submitTriggered="deleteComparativo(dialogDeleteData.id)"
          >
            <template slot="title">
              Deletar Comparativo #{{ dialogDeleteData.id }}
            </template>
            <template slot="body">
              Tem certeza que deseja deletar o comparativo
              <strong>{{ dialogDeleteData.titulo }}</strong
              >?
            </template>
          </dialog-delete>

          <general-dialog-detalhes
            ref="dialogDetalhes"
            title="Detalhes"
            :showDialog="dialogDetalhes"
            @changeValue="dialogDetalhes = $event"
          >
            <template slot="body">
              <v-data-table
                :headers="[
                  {
                    text: 'Parâmetro',
                    value: 'parametro',
                    sortable: false
                  },
                  {
                    text: 'Valor',
                    value: 'valor',
                    sortable: false
                  }
                ]"
                :items="dialogDetalhesData"
                hide-default-footer
                class="elevation-1 style-table-dialog-detalhes"
                disable-pagination
                :mobile-breakpoint="10"
              >
                <template v-slot:item="row">
                  <tr>
                    <td
                      class="button"
                      width="210px"
                    >
                      {{ row.item.parametro }}
                    </td>
                    <td
                      class="button"
                      v-if="row.item.parametro != 'ODIS:' || !row.item.valor"
                    >
                      {{ row.item.valor }}
                    </td>
                    <td
                      class="button"
                      v-else-if="row.item.valor.length == 0"
                    />
                    <td
                      class="button"
                      v-else-if="row.item.valor.length == 1"
                    >
                      {{ row.item.valor[0] }}
                    </td>
                    <td
                      class="button"
                      v-else
                      style="padding-left: 0px !important"
                    >
                      <v-textarea
                        rows="4"
                        no-resize
                        readonly
                        class="ml-4 mt-0 pt-0"
                        :value="
                          $refs.dialogDetalhes.updateValueTextArea(
                            row.item.valor
                          )
                        "
                      />
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </template>
          </general-dialog-detalhes>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import SigRComparaService from '@/services/SigRComparaService';
import AuthService from '@/services/AuthService';
import refreshDataMixins from '@/mixins/refreshDataMixins';
import tourMixins from '@/mixins/tourMixins';
import profilePermissionsMixin from '@/mixins/profilePermissionsMixin';

export default {
  name: 'SigRComparaHistorico',
  mixins: [refreshDataMixins, tourMixins, profilePermissionsMixin],
  components: {
    GeneralProgressBars: () =>
      import('@/components/general/GeneralProgressBars.vue'),
    GeneralStatus: () => import('@/components/general/GeneralStatus.vue'),
    GeneralDialogDetalhes: () =>
      import('@/components/general/GeneralDialogDetalhes'),
    DialogDelete: () => import('@/components/general/DialogDelete')
  },
  data() {
    return {
      comparativos: [],
      usuarioAdmin: false,
      search: '',
      downloadProgressData: {},
      dialogDelete: false,
      dialogDeleteData: {
        id: undefined,
        nome: undefined
      },
      dialogDetalhes: false,
      dialogDetalhesData: [],
      arrTour: [
        {
          element: '#btn-novo-comparativo',
          intro: 'Clicando neste botão você poderá criar novos comparativos.',
          scrollTo: 'tooltip',
          position: 'right'
        },
        {
          element: '#comparativo-historico-search',
          intro:
            'Neste campo você poderá pesquisar por comparativos já existentes.',
          scrollTo: 'tooltip',
          position: 'bottom'
        },
        {
          element: '#action-download-comparativo',
          intro:
            'Clicando neste ícone você poderá fazer o download em .XLSX do resultado de seu comparativo.',
          scrollTo: 'tooltip',
          position: 'left'
        },
        {
          element: '#action-edit-comparativo',
          intro: 'Clicando neste ícone você poderá editar seu comparativo.',
          scrollTo: 'tooltip',
          position: 'left'
        },
        {
          element: '#action-more-comparativo',
          intro:
            'Aqui você acessa outras opções para seu comparativo, como ver mais detalhes, executá-lo novamente, duplicá-lo ou deletá-lo.',
          scrollTo: 'tooltip',
          position: 'left'
        }
      ],
      loading: false
    };
  },
  mounted() {
    this.refreshData(this.getComparativos);
    this.getUsuarioAdmin();
  },
  computed: {
    usuario() {
      return this.$store.getters.getUser;
    }
  },
  methods: {
    getComparativos() {
      this.comparativos = [];
      this.loading = true;

      SigRComparaService.getComparativos()
        .then(({ data }) => {
          this.comparativos = data.data;
          this.iniciarTourPagina();
        })
        .catch((error) => {
          console.error(error);
          this.$toast.error('Erro ao recuperar os comparativos.', '', {
            position: 'topRight'
          });
        })
        .finally(() => (this.loading = false));
    },
    async getUsuarioAdmin() {
      this.usuarioAdmin = await AuthService.userIsAdmin();
    },
    canEditItem(item) {
      return (
        item.status !== 'EXECUTANDO' &&
        (this.usuarioAdmin || this.usuario.id === item.usuario_id)
      );
    },
    canRunItem(item) {
      return item.status !== 'EXECUTANDO' && item.status !== 'AGUARDANDO';
    },
    canDeleteItem(item) {
      return (
        item.status !== 'EXECUTANDO' &&
        (this.usuarioAdmin || this.usuario.id === item.usuario_id)
      );
    },
    baixarComparativo(comparativo) {
      const id = comparativo.id;
      const timestamp = dayjs(new Date()).format('YYYYMMDDHHmm');
      const nomeComparativo = `${comparativo.titulo}-${timestamp}.xlsx`;

      this.$set(this.downloadProgressData, id, { id });
      this.$toast.info('Preparando seu arquivo para download.', '', {
        position: 'topRight',
        timeout: 2000
      });

      SigRComparaService.baixarComparativo(comparativo.id, {
        timeout: 60 * 60 * 1000,
        onDownloadProgress: (progressEvent) => {
          let progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          let isComputable = progressEvent.lengthComputable;
          this.$set(this.downloadProgressData, id, {
            progress,
            isComputable,
            id
          });
        }
      })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', nomeComparativo);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.$toast.error(
            'Erro ao baixar o arquivo xlsx com o resultado do comparativo.',
            '',
            { position: 'topRight' }
          );
          console.error('Erro: ', error);
        })
        .finally(() => {
          this.$delete(this.downloadProgressData, id);
        });
    },
    executarComparativo(id) {
      SigRComparaService.executarComparativo(id)
        .then(() => {
          this.$toast.success(
            'Comparativo agendado para execução com sucesso.',
            '',
            { position: 'topRight' }
          );
          this.getComparativos();
        })
        .catch((error) => {
          this.$toast.error(
            'Erro ao agendar o comparativo para execução.',
            '',
            { position: 'topRight' }
          );
          console.error(error);
        });
    },
    duplicarComparativo(id) {
      SigRComparaService.duplicarComparativo(id)
        .then(() => {
          this.$toast.success('Comparativo duplicado com sucesso.', '', {
            position: 'topRight'
          });
          this.getComparativos();
        })
        .catch((error) => {
          this.$toast.error(
            'Erro ao duplicar o comparativo para execução.',
            '',
            { position: 'topRight' }
          );
          console.error(error);
        });
    },
    deleteComparativo(id) {
      SigRComparaService.deleteComparativo(id)
        .then(() => {
          this.$toast.success('Comparativo removido com sucesso.', '', {
            position: 'topRight'
          });
          this.getComparativos();
        })
        .catch((error) => {
          this.$toast.error('Erro ao deletar o comparativo.', '', {
            position: 'topRight'
          });
          console.error(error);
        });

      this.dialogDelete = false;
    },
    parseToDateBR(str) {
      return str && str.length >= 10 ? dayjs(str).format('DD/MM/YYYY') : str;
    },
    parseToMonthBR(str) {
      return str && str.length >= 10 ? dayjs(str).format('MM/YYYY') : str;
    },
    openDialogDelete(id, titulo) {
      this.dialogDeleteData = { id, titulo };
      this.dialogDelete = true;
    },
    openDialogDetalhes(comparativo) {
      let dialogDetalhesData = [
        {
          parametro: 'Tipo:',
          valor: comparativo.tipo
        },
        {
          parametro: 'Data da BDGD:',
          valor:
            this.parseToMonthBR(comparativo.data_bdgd) +
            ` v${comparativo.versao_bdgd}`
        }
      ];

      if (comparativo.tipo !== 'Apenas BDGD') {
        dialogDetalhesData.push({
          parametro: 'Base Contábil:',
          valor: comparativo.tipo_base_contabil
        });

        comparativo.tipo_base_contabil === 'AIS'
          ? dialogDetalhesData.push({
              parametro: 'Data do AIS:',
              valor: this.parseToMonthBR(comparativo.data_ais)
            })
          : dialogDetalhesData.push({
              parametro: 'Data do Laudo:',
              valor: this.parseToMonthBR(comparativo.data_laudo)
            });
      }

      dialogDetalhesData = dialogDetalhesData.concat([
        {
          parametro: 'Data Imob Início:',
          valor: this.parseToDateBR(comparativo.data_imobilizacao_inicio)
        },
        {
          parametro: 'Data Imob Fim:',
          valor: this.parseToDateBR(comparativo.data_imobilizacao_fim)
        },
        {
          parametro: 'Comparar por TI:',
          valor: comparativo.compara_ti
        },
        {
          parametro: 'Comparar por ano:',
          valor: comparativo.compara_ano
        },
        {
          parametro: 'Sobra Física BDGD:',
          valor: comparativo.sobra_fisica
        },
        {
          parametro: 'TUCs:',
          valor: JSON.parse(comparativo.tucs).join(', ')
        },
        {
          parametro: 'ODIs:',
          valor: JSON.parse(comparativo.odis).join(', ')
        }
      ]);

      this.dialogDetalhesData = dialogDetalhesData;
      this.dialogDetalhes = true;
    },
    iniciarTourPagina() {
      if (this.tourFuncCalled) return;

      let arrTourFormatted = this.comparativos.length
        ? this.arrTour
        : this.arrTour.splice(0, 2);
      this.iniciarTour(arrTourFormatted);
    }
  }
};
</script>

<style>
@media screen and (min-width: 800px) {
  .sig-r-table table tr td:nth-child(2) {
    max-width: 200px;
  }

  .sig-r-table table tr td:nth-child(5),
  .sig-r-table table tr td:nth-child(6) {
    white-space: nowrap !important;
  }
}

@media screen and (max-width: 480px) {
  .bs-sig-r {
    width: 215.36px !important;
  }
}
</style>
