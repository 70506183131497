var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-material-card',{staticClass:"px-5 py-4 ml-0",attrs:{"color":"primary","icon":"mdi-table-merge-cells","inline":""},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"display-2 font-weight-light"},[_vm._v("SIG-R Compara")])]},proxy:true}])},[(_vm.accessReleased('SIGR_COMPARA_ADICIONAR'))?_c('v-btn',{staticClass:"mt-5",staticStyle:{"float":"left"},attrs:{"id":"btn-novo-comparativo","color":"success","dark":"","elevation":"1","relative":"","text":"","medium":""},on:{"click":function($event){return _vm.$router.push('edit')}}},[_c('v-icon',{attrs:{"left":"","size":"30px"}},[_vm._v(" mdi-plus-circle ")]),_vm._v(" Novo Comparativo ")],1):_vm._e(),_c('v-text-field',{staticClass:"ml-auto mt-4 break-search bs-sig-r",staticStyle:{"max-width":"250px"},attrs:{"id":"comparativo-historico-search","append-icon":"mdi-magnify","label":"Procurar","hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('general-progress-bars',{staticClass:"mt-11",attrs:{"items":_vm.downloadProgressData,"prefixText":"Baixando Comparativo"}}),_c('v-divider',{staticClass:"mt-10"}),_c('v-data-table',{staticClass:"sig-r-table",attrs:{"headers":[
            {
              text: '#',
              value: 'id'
            },
            {
              text: 'Título',
              value: 'titulo'
            },
            {
              text: 'Criado por',
              value: 'usuario'
            },
            {
              text: 'Executado em',
              value: 'data_processamento'
            },
            {
              text: 'Status',
              value: 'status'
            },
            {
              sortable: false,
              text: 'Ações',
              value: 'actions',
              class: 'pl-4'
            }
          ],"items":_vm.comparativos,"search":_vm.search,"headerProps":{
            sortByText: 'Ordenar Por'
          },"footer-props":{
            itemsPerPageText: 'Itens por Página'
          },"mobile-breakpoint":800,"loading":_vm.loading,"hide-default-header":_vm.loading,"hide-default-footer":_vm.loading,"no-data-text":"Nenhum comparativo disponível","no-results-text":"Nenhum registro correspondente encontrado"},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.data_processamento",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("parseToDateBR")(item.data_processamento))+" ")]}},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [_c('general-status',{attrs:{"status":item.status}})]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{staticClass:"px-1",class:item.status != 'SUCESSO' ? 'cursor-block' : '',attrs:{"id":"action-download-comparativo","disabled":item.status != 'SUCESSO',"color":"gray","min-width":"0","fab":"","icon":"","x-small":"","loading":_vm.downloadProgressData[item.id] !== undefined ? true : false},on:{"click":function($event){return _vm.baixarComparativo(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-download ")])],1),(_vm.accessReleased('SIGR_COMPARA_EDITAR'))?_c('v-btn',{staticClass:"px-1 ml-n1",class:!_vm.canEditItem(item) ? 'cursor-block' : '',attrs:{"id":"action-edit-comparativo","disabled":!_vm.canEditItem(item),"color":"gray","min-width":"0","fab":"","icon":"","x-small":""},on:{"click":function($event){return _vm.$router.push(("edit/" + (item.id)))}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1):_vm._e(),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"id":"action-more-comparativo","color":"white","height":"22px","width":"22px","fab":"","x-small":"","elevation":"1"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',[(_vm.canRunItem(item))?_c('v-list-item',{on:{"click":function($event){return _vm.executarComparativo(item.id)}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-play ")]),_vm._v(" Executar ")],1)],1):_vm._e(),(item.status == 'SUCESSO')?_c('v-list-item',{on:{"click":function($event){return _vm.duplicarComparativo(item.id)}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-content-copy ")]),_vm._v(" Duplicar ")],1)],1):_vm._e(),(_vm.canDeleteItem(item))?_c('v-list-item',{on:{"click":function($event){return _vm.openDialogDelete(item.id, item.titulo)}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-trash-can-outline ")]),_vm._v(" Deletar ")],1)],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){return _vm.openDialogDetalhes(item)}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-file-find-outline ")]),_vm._v(" Detalhes ")],1)],1)],1)],1)]}},{key:"footer.page-text",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":_vm.getComparativos}},'v-icon',attrs,false),on),[_vm._v("mdi-refresh")])]}}])},[_c('span',[_vm._v("Clique aqui para recarregar os comparativos")])])]},proxy:true}],null,true)}),_c('dialog-delete',{attrs:{"dialog":_vm.dialogDelete},on:{"update:dialog":function($event){_vm.dialogDelete = $event},"submitTriggered":function($event){return _vm.deleteComparativo(_vm.dialogDeleteData.id)}}},[_c('template',{slot:"title"},[_vm._v(" Deletar Comparativo #"+_vm._s(_vm.dialogDeleteData.id)+" ")]),_c('template',{slot:"body"},[_vm._v(" Tem certeza que deseja deletar o comparativo "),_c('strong',[_vm._v(_vm._s(_vm.dialogDeleteData.titulo))]),_vm._v("? ")])],2),_c('general-dialog-detalhes',{ref:"dialogDetalhes",attrs:{"title":"Detalhes","showDialog":_vm.dialogDetalhes},on:{"changeValue":function($event){_vm.dialogDetalhes = $event}}},[_c('template',{slot:"body"},[_c('v-data-table',{staticClass:"elevation-1 style-table-dialog-detalhes",attrs:{"headers":[
                {
                  text: 'Parâmetro',
                  value: 'parametro',
                  sortable: false
                },
                {
                  text: 'Valor',
                  value: 'valor',
                  sortable: false
                }
              ],"items":_vm.dialogDetalhesData,"hide-default-footer":"","disable-pagination":"","mobile-breakpoint":10},scopedSlots:_vm._u([{key:"item",fn:function(row){return [_c('tr',[_c('td',{staticClass:"button",attrs:{"width":"210px"}},[_vm._v(" "+_vm._s(row.item.parametro)+" ")]),(row.item.parametro != 'ODIS:' || !row.item.valor)?_c('td',{staticClass:"button"},[_vm._v(" "+_vm._s(row.item.valor)+" ")]):(row.item.valor.length == 0)?_c('td',{staticClass:"button"}):(row.item.valor.length == 1)?_c('td',{staticClass:"button"},[_vm._v(" "+_vm._s(row.item.valor[0])+" ")]):_c('td',{staticClass:"button",staticStyle:{"padding-left":"0px !important"}},[_c('v-textarea',{staticClass:"ml-4 mt-0 pt-0",attrs:{"rows":"4","no-resize":"","readonly":"","value":_vm.$refs.dialogDetalhes.updateValueTextArea(
                          row.item.valor
                        )}})],1)])]}}])})],1)],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }